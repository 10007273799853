// src/websiteInfo.ts
var PROJECT = {
  title: "Macro MRJ",
  subtitle: "My great monorepo"
};
var getWebsiteInfo = () => {
  return { title: `->>${PROJECT.title}<<-`, subtitle: `-->>${PROJECT.subtitle}<<--` };
};

// src/utils/formSchema.ts
import * as Yup from "yup";
import moment from "moment";

// src/utils/constants.ts
var APP = "/api/app";
var ALPHANUM_REGEX = /^[A-Za-z0-9]*$/;
var maxRangoDiasFactura = 90;

// src/utils/enums.ts
var ESTADOS_BOOLEAN = {
  ACTIVO: true,
  DESACTIVADO: false
};
var ESTADOS_USUARIO = {
  ACTIVO: 1,
  BLOQUEDADO: 2,
  ELIMINADO: 3
};
var ESTADOS_FORMULARIO = {
  BORRADOR: 10,
  ENVIADO_MACRO: 20,
  VALIDADO_OBS: 30,
  FORM_VALIDADO: 40,
  PAGO_PARCIAL: 50,
  PAGO_COMPLETO: 60,
  PAGO_RECIBIDO: 70,
  ELIMINADO: 80
};
var ESTADOS_FACTURA = {
  CREADA: 1,
  VALIDADA: 2,
  PAGADA: 3,
  RECHAZADA: 4,
  ELIMINADA: 5,
  COMP_PAGO: 6,
  NOTA_CREDITO_SOLICITADA: 7,
  NOTA_CREDITO_ENVIADA: 8
};
var TIPOS_EMPRESA = {
  MACRO: 1,
  PROVEEDOR: 2
};
var MONEDAS = {
  MXN: 1,
  USD: 2,
  EUR: 3
};
var LARGO = {
  MAX_NOMBRE: 100,
  MAX_EMAIL: 256,
  MIN_PASS: 6,
  MAX_GLOSA: 150,
  MAX_RFC: 15
};
var ROLES = {
  ADMIN_PLATAFORMA: 1e3,
  ADMIN_NEGOCIO: 2e3,
  FINANZAS: 2100,
  CONTABILIDAD: 2200,
  VENTAS: 2300,
  PROVEEDOR: 2400,
  COMPRAS: 2500,
  ALMACEN: 2600
};
var FUNCIONALIDADES = {
  Administrador_Plataforma: 100,
  Administrador_Negocio: 200,
  Crear_Usuario: 301,
  Ver_Usuario: 302,
  Actualizar_Usuario: 303,
  Eliminar_Usuario: 304,
  Crear_Empresa: 401,
  Ver_Empresa: 402,
  Actualizar_Empresa: 403,
  Eliminar_Empresa: 404,
  Crear_Formulario: 501,
  Ver_Formulario: 502,
  Actualizar_Formulario: 503,
  Eliminar_Formulario: 504,
  Actualizar_Estado_Formulario: 505,
  Validar_Formulario: 601,
  Pagar_Formulario: 602,
  Ver_Roles: 701,
  Agregar_Bitacora: 801,
  Ver_Bitacora: 802,
  Ver_Acciones: 803,
  Ver_Reportes: 804
};
var MIME_TYPES = {
  PDF: "application/pdf",
  XML: "text/xml",
  JPG: "image/jpeg",
  PNG: "image/png"
};
var ACCIONES = {
  EMPRESA_CREADA: 1,
  EMPRESA_MODIFICADA: 2,
  FORMULARIO_CREADO: 3,
  FORMULARIO_MODIFICADO: 4,
  FACTURA_CREADA: 5,
  FACTURA_ACTUALIZADA: 6,
  FORMULARIO_ELIMINADO: 7,
  FACTURA_ELIMINADA: 8
};
var URI = {
  ACCIONES: `${APP}/acciones`,
  BITACORAS: `${APP}/bitacoras`,
  EMPRESAS: `${APP}/empresas`,
  ESTADOS: `${APP}/estados`,
  FORMULARIOS: `${APP}/formularios`,
  GENERALES: `${APP}/generales`,
  MONEDAS: `${APP}/monedas`,
  PDF: `${APP}/PDF`,
  REPORTES: `${APP}/reportes`,
  ROLES: `${APP}/roles`,
  USUARIOS: `${APP}/usuarios`
};
var ESTADOS_PDF = /* @__PURE__ */ ((ESTADOS_PDF2) => {
  ESTADOS_PDF2[ESTADOS_PDF2["SUBIDO"] = 0] = "SUBIDO";
  ESTADOS_PDF2[ESTADOS_PDF2["APROBADO"] = 1] = "APROBADO";
  ESTADOS_PDF2[ESTADOS_PDF2["RECHAZADO"] = 2] = "RECHAZADO";
  return ESTADOS_PDF2;
})(ESTADOS_PDF || {});

// src/utils/formSchema.ts
var usuarioSchema = Yup.object().shape({
  nombre: Yup.string().required("error.nombre-vacio").max(LARGO.MAX_NOMBRE, "error.max-largo-descripcion"),
  apPaterno: Yup.string().required("error.paterno-vacio").max(LARGO.MAX_NOMBRE, "error.max-largo-descripcion"),
  apMaterno: Yup.string().notRequired().max(LARGO.MAX_NOMBRE, "error.max-largo-descripcion"),
  isEdicion: Yup.boolean(),
  password: Yup.string().when("isEdicion", {
    is: true,
    then: Yup.string().notRequired().min(LARGO.MIN_PASS, "error.largo-minimo-password")
  }),
  email: Yup.string().email("error.email-invalido").required("error.email-vacio").max(LARGO.MAX_EMAIL, "error.max-largo-descripcion"),
  idRol: Yup.string().required("error.combo-roles")
});
var empresaSchema = Yup.object().shape({
  glosa: Yup.string().required("error.nombre-empresa-vacio").max(LARGO.MAX_GLOSA, "error.max-largo-descripcion"),
  isEdicion: Yup.boolean(),
  rfc: Yup.string().required("error.rfc-vacio").max(LARGO.MAX_RFC, "error.max-largo-descripcion"),
  emailArr: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email("error.email-invalido").required("error.email-vacio").max(LARGO.MAX_EMAIL, "error.max-largo-descripcion")
    })
  ),
  phoneArr: Yup.array().of(
    Yup.object().shape({
      telefono: Yup.string().notRequired()
    })
  ),
  activo: Yup.string().when("isEdicion", {
    is: true,
    then: Yup.string().required("error.seleccionar-estado")
  })
});
var facturaSchema = Yup.object().shape({
  folioFactura: Yup.string().required("error.folio-factura-vacio"),
  monto: Yup.number().positive("error.mayor-cero").required("error.monto-factura-vacio"),
  fechaEmision: Yup.date().max(moment(), "error.no-mayor-hoy").required("error.fecha-emision-vacio").nullable(),
  fechaVencimiento: Yup.date().when("fechaEmision", (fechaEmision, schema) => {
    if (!moment(fechaEmision).isValid())
      return Yup.string().required("error.fecha-vencimiento-vacio").nullable();
    return schema.min(moment(fechaEmision), "error.venc-mayor-emision").max(
      moment(fechaEmision).add(maxRangoDiasFactura, "day"),
      "error.limite-dias"
    ).required("error.fecha-vencimiento-vacio").nullable();
  }),
  pdf: Yup.string().required("error.pdf-vacio"),
  xml: Yup.string().required("error.xml-vacio")
});
var loginSchema = Yup.object().shape({
  email: Yup.string().email("error.email-invalido").required("error.email-vacio").max(LARGO.MAX_EMAIL, "error.max-largo-descripcion"),
  password: Yup.string().required("error.password-vacio").label("Password")
});
var formularioSchema = Yup.object().shape({
  glosa: Yup.string().required("error.nombre-formulario-vacio").max(LARGO.MAX_GLOSA, "error.max-largo-descripcion"),
  monto: Yup.number().positive("error.mayor-cero").required("error.monto-gasto-vacio"),
  gasto: Yup.string().required("error.gasto-vacio"),
  idMoneda: Yup.string().required("error.seleccionar-moneda"),
  pdf: Yup.string().required("error.pdf-vacio"),
  xml: Yup.string().required("error.xml-vacio"),
  isEdicion: Yup.boolean()
});
var formularioFacturaSchema = Yup.object().shape({
  glosa: Yup.string().required("error.nombre-formulario-vacio").max(LARGO.MAX_GLOSA, "error.max-largo-descripcion"),
  nroOrdenCompra: Yup.number().positive("error.mayor-cero").integer("error.numero-entero").required("error.nro-orden-compra-vacio"),
  nroRecepcion: Yup.number().positive("error.mayor-cero").integer("error.numero-entero").required("error.nro-recepcion-vacio"),
  cantPagos: Yup.number().positive("error.mayor-cero").integer("error.numero-entero").required("error.cant-pagos-vacio"),
  montoTotal: Yup.number().positive("error.mayor-cero").required("error.monto-total-vacio"),
  idMoneda: Yup.string().required("error.seleccionar-moneda"),
  pdfOC: Yup.string().required("error.pdf-vacio"),
  pdfEntrega: Yup.string().required("error.pdf-vacio"),
  folioFactura: Yup.string().when("isEdicion", {
    is: false,
    then: Yup.string().required("error.folio-factura-vacio")
  }),
  monto: Yup.number().when("isEdicion", {
    is: false,
    then: Yup.number().positive("error.mayor-cero").required("error.monto-factura-vacio")
  }),
  fechaEmision: Yup.date().when("isEdicion", {
    is: false,
    then: Yup.date().max(moment(), "error.no-mayor-hoy").required("error.fecha-emision-vacio").nullable()
  }),
  fechaVencimiento: Yup.date().when("isEdicion", {
    is: false,
    then: Yup.date().when("fechaEmision", (fechaEmision, schema) => {
      if (!moment(fechaEmision).isValid())
        return Yup.string().required("error.fecha-vencimiento-vacio").nullable();
      return schema.min(moment(fechaEmision), "error.venc-mayor-emision").max(
        moment(fechaEmision).add(maxRangoDiasFactura, "day"),
        "error.limite-dias"
      ).required("error.fecha-vencimiento-vacio").nullable();
    })
  }),
  pdf: Yup.string().when("isEdicion", {
    is: false,
    then: Yup.string().required("error.pdf-vacio")
  }),
  xml: Yup.string().when("isEdicion", {
    is: false,
    then: Yup.string().required("error.xml-vacio")
  }),
  isEdicion: Yup.boolean()
});
var aprobarRechazarSchema = Yup.object().shape({
  comentarios: Yup.string().notRequired()
});
var olvidoPassPaso0 = Yup.object().shape({
  email: Yup.string().email("error.email-invalido").required("error.email-vacio").max(LARGO.MAX_EMAIL, "error.max-largo-descripcion")
});
var olvidoPassPaso1 = Yup.object().shape({
  codigo: Yup.string().matches(/^[0-9]{6}$/, "error.codigo-invalido").required("error.codigo-vacio")
});
var olvidoPassPaso2 = Yup.object().shape({
  pass01: Yup.string().label("Password").min(LARGO.MIN_PASS, "error.largo-minimo-password"),
  pass02: Yup.string().min(LARGO.MIN_PASS, "error.largo-minimo-password").oneOf([Yup.ref("pass01"), null], "error.pass-no-coincide")
});
var solicitarNotaCreditoSchema = Yup.object().shape({
  montoNotaCredito: Yup.number().positive("error.mayor-cero").required("error.monto-nota-credito-vacio")
});
var cargarNotaCreditoSchema = Yup.object().shape({
  pdf: Yup.string().required("error.pdf-vacio"),
  xml: Yup.string().required("error.xml-vacio")
});
var cargarComplementoPagoSchema = Yup.object().shape({
  pdf: Yup.string().required("error.pdf-vacio"),
  xml: Yup.string().required("error.xml-vacio")
});
var fieldNumberSchema = Yup.object().shape({
  field: Yup.number().required("error.campo-vacio")
});

// src/utils/fieldSchema.ts
import * as Yup2 from "yup";
var idFormularioSchema = Yup2.object().shape({
  idFormulario: Yup2.string().required("error.id-formulario-vacio").matches(ALPHANUM_REGEX, "error.caracteres-incorrectos")
});
var glosaSchema = Yup2.object().shape({
  glosa: Yup2.string().required("error.nombre-formulario-vacio").max(LARGO.MAX_GLOSA, "error.max-largo-descripcion")
});
var passwordSchema = Yup2.object().shape({
  password: Yup2.string().required("error.password-vacio").min(LARGO.MIN_PASS, "error.largo-minimo-password")
});

// src/utils/yup.ts
import { ValidationError } from "yup";
function validateRequest(data, schema) {
  const _data = schema.validateSync(data, {
    abortEarly: false,
    strict: true
  });
  return _data;
}
var ValErr = ValidationError;
export {
  ACCIONES,
  ESTADOS_BOOLEAN,
  ESTADOS_FACTURA,
  ESTADOS_FORMULARIO,
  ESTADOS_PDF,
  ESTADOS_USUARIO,
  FUNCIONALIDADES,
  LARGO,
  MIME_TYPES,
  MONEDAS,
  ROLES,
  TIPOS_EMPRESA,
  URI,
  ValErr,
  aprobarRechazarSchema,
  cargarComplementoPagoSchema,
  cargarNotaCreditoSchema,
  empresaSchema,
  facturaSchema,
  fieldNumberSchema,
  formularioFacturaSchema,
  formularioSchema,
  getWebsiteInfo,
  glosaSchema,
  idFormularioSchema,
  loginSchema,
  olvidoPassPaso0,
  olvidoPassPaso1,
  olvidoPassPaso2,
  passwordSchema,
  solicitarNotaCreditoSchema,
  usuarioSchema,
  validateRequest
};
