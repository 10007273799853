import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import { LOGOUT_USER } from './actions';

const appReducer = combineReducers({
  menu,
  settings,
  authUser,
});

const reducers = (stateIn, action) => {
  const state = action.type === LOGOUT_USER ? undefined : stateIn;

  return appReducer(state, action);
};

export default reducers;
