/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-useless-catch */
import Resizer from 'react-image-file-resizer';
import {
  darkDefaultColor,
  defaultColor,
  defaultDirection,
  defaultLocale,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from '../constants/defaultValues';
// eslint-disable-next-line import/no-cycle
import { FUNCIONALIDADES } from '@macro/shared';
import { ESTADOS_FORMULARIO, ROLES } from '@macro/shared';

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    if (darkThemeMq.matches) currentColor = darkDefaultColor;

    if (localStorage.getItem(themeColorStorageKey))
      currentColor = localStorage.getItem(themeColorStorageKey);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};

export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

// TODO: pensar en traer funcionalidades del usuario cada vez que se traiga el refreshtoken
export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('current_user') !== null
        ? JSON.parse(localStorage.getItem('current_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('current_user');
      localStorage.removeItem('auth-token');
      localStorage.removeItem('datos');
      localStorage.removeItem('refresh-auth-token');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const checkFuncionalidades = (funcionalidades) => {
  const usuario = getCurrentUser();

  if (!usuario) throw new Error('No hay usuario');

  const ADMIN = [
    FUNCIONALIDADES.Administrador_Plataforma,
    FUNCIONALIDADES.Administrador_Negocio,
  ];

  return funcionalidades.some(
    (func) =>
      usuario.funcionalidades.includes(func) ||
      usuario.funcionalidades.some((f) => ADMIN.includes(f))
  );
};

export const checkFuncUsuario = (funcionalidades) => {
  const usuario = getCurrentUser();

  return funcionalidades.some((func) => usuario.funcionalidades.includes(func));
};

export const subeFomularios = () =>
  [ROLES.PROVEEDOR, ROLES.VENTAS].includes(getCurrentUser().idRolActivo);

export const isVendedor = () =>
  [ROLES.VENTAS].includes(getCurrentUser().idRolActivo);

export const preValidation = (funcionalidades) => {
  try {
    if (!checkFuncionalidades(funcionalidades))
      throw new Error('error.no-autorizado');
  } catch (error) {
    throw error;
  }
};

export const jsonToArray = (json) => {
  const salida = [];
  let tmp = {};

  for (const i in json) {
    tmp.value = json[i];
    tmp.label = i[0].toUpperCase() + i.slice(1).toLowerCase();
    tmp.label = tmp.label.replace('_', ' ');
    salida.push(tmp);
    tmp = {};
  }
  return salida;
};

export const getImagen = (imgURL) =>
  imgURL || process.env.REACT_APP_IMG_DEFAULT;

export const priceFormat = (value) => {
  if (value) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 0,
    }).format(value);
  }
  return '$0';
};

export const numberFormat = (value) => {
  if (Number.isNaN(value)) return 0;
  if (value) {
    return new Intl.NumberFormat('es-MX').format(value);
  }
  return 0;
};

export const convertToNumber = (value) => {
  if (Number.isNaN(value * 1)) return 0;
  return value;
};

export const getMinMax = (valor, isMin = false) => {
  const closeTo5 = (x) => Math.floor(x / 5) * 5;
  const param = 10;
  if (isMin) return valor - param < 0 ? 0 : closeTo5(valor - param);

  return valor + param > 100 ? 100 : closeTo5(valor + param);
};

export const addEmpty = (glosa, arr) => [{ id: '', glosa }, ...arr];

export const getQuery = (query, paramQuery, value) => {
  let qry = query;
  if (value) {
    qry += query.length > 0 ? '&' : '?';
    qry += `${paramQuery}=${value}`;
  }
  return qry;
};

export const getStrQuery = (params) => {
  let query = '';
  query = getQuery(query, 'currentPage', params?.currentPage);
  query = getQuery(query, 'pageSize', params?.pageSize);
  query = getQuery(query, 'orderBy', params?.orderBy?.id);
  query = getQuery(query, 'search', params?.search);

  return query;
};

export const comboConvert = (item) => ({ value: item.id, label: item.glosa });

export const usuarioConvert = (item) => ({
  value: item.id,
  label: `${item.nombre} ${item.ap_paterno}`,
});

export const getValueEnum = (json, id) => {
  let glosa = '';

  Object.keys(json).forEach((key) => {
    if (id === json[key]) {
      glosa = key[0].toUpperCase() + key.slice(1).toLowerCase();
      glosa = glosa.replace('_', ' ');
    }
  });
  return glosa;
};

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1280,
      720,
      'JPEG',
      85,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });

export const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

export const isAdminPlataforma = () =>
  checkFuncUsuario([FUNCIONALIDADES.Administrador_Plataforma]);

export const isAdminNegocio = () =>
  checkFuncUsuario([FUNCIONALIDADES.Administrador_Negocio]);

export const isAdmin = () => isAdminPlataforma() || isAdminNegocio();

export const getSelectValue = (item) => item.value ?? item;
export const getSelectLabel = (item) => item.label ?? item;

const paginate = (array, pageSize, pageNumber) =>
  array?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize) || [];

export const paginatedResult = (array, { pageSize, currentPage, search }) => ({
  currentPage,
  pageSize,
  search: search !== '',
  totalItem: array?.length,
  totalPage: Math.ceil(array?.length / pageSize),
  data: paginate(array, pageSize, currentPage),
});

export const getJsonKeysFromArray = (array) => {
  if (array?.length === 0) return [];

  return Object.keys(array[0]);
};

const verPDF = (url) => window.open(url);

const descargarPDF = (url, nombreArchivo) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', nombreArchivo);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
};

export const processPDF = async (data, nombreArchivo) => {
  const url = URL.createObjectURL(
    new Blob([data], { type: 'application/pdf' })
  );

  if (nombreArchivo) {
    descargarPDF(url, nombreArchivo);
  } else verPDF(url);
};

export const onWheel = (e) => e.target.type === 'number' && e.target.blur();

export const getMimetype = (type) => {
  const ext = {
    pdf: 'application/pdf',
    xml: 'text/xml',
  };

  return ext[type] ?? '';
};

export const isVentas = () => getCurrentUser().idRolActivo === ROLES.VENTAS;

export const isProveedor = () =>
  getCurrentUser().idRolActivo === ROLES.PROVEEDOR;

export const isContabilidad = () =>
  getCurrentUser().idRolActivo === ROLES.CONTABILIDAD || isAdmin();

export const isFinanzas = () =>
  getCurrentUser().idRolActivo === ROLES.FINANZAS || isAdmin();

export const isCompras = () =>
  getCurrentUser().idRolActivo === ROLES.COMPRAS || isAdmin();

export const isAlmacen = () =>
  getCurrentUser().idRolActivo === ROLES.ALMACEN || isAdmin();

export const getBadgeColorFormularios = (idEstado) => {
  const {
    BORRADOR,
    VALIDADO_OBS,
    VALIDADO_COMPRAS,
    FORM_VALIDADO,
    ELIMINADO,
    PAGO_PARCIAL,
    PAGO_COMPLETO,
  } = ESTADOS_FORMULARIO;
  const mapCB = {
    [BORRADOR]: 'primary',
    [VALIDADO_COMPRAS]: 'light',
    [FORM_VALIDADO]: 'light',
    [ELIMINADO]: 'danger',
    [VALIDADO_OBS]: 'warning',
    [PAGO_PARCIAL]: 'warning',
    [PAGO_COMPLETO]: 'success',
  };

  return mapCB[idEstado] ?? 'secondary';
};
