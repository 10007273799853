// eslint-disable-next-line import/no-cycle
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  CHECK_CODE,
  CHECK_CODE_SUCCESS,
  CHECK_CODE_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  LOGOUT_USER,
} from '../actions';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const forgotPassword = (params) => ({
  type: FORGOT_PASSWORD,
  payload: params,
});
export const forgotPasswordSuccess = (items) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: items,
});
export const forgotPasswordError = (error) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: error,
});

export const checkCode = (params) => ({
  type: CHECK_CODE,
  payload: params,
});
export const checkCodeSuccess = (items) => ({
  type: CHECK_CODE_SUCCESS,
  payload: items,
});
export const checkCodeError = (error) => ({
  type: CHECK_CODE_ERROR,
  payload: error,
});

export const resetPassword = (params) => ({
  type: RESET_PASSWORD,
  payload: params,
});
export const resetPasswordSuccess = (items) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: items,
});
export const resetPasswordError = (error) => ({
  type: RESET_PASSWORD_ERROR,
  payload: error,
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});
