/* eslint-disable no-useless-catch */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';

import { API } from '../../helpers/axios';
import { adminRoot } from '../../constants/defaultValues';
import { setCurrentUser } from '../../helpers/Utils';
import deviceStorage from '../../helpers/deviceStorage';

import {
  LOGIN_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHECK_CODE,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  checkCodeSuccess,
  checkCodeError,
} from './actions';

const MAIN_URI = '/api/pre-auth';

const loginWithEmailPasswordAsync = async (formValues) => {
  try {
    const { data } = await API.post('/api/auth/login', formValues);

    deviceStorage.saveJWT(data);

    return jwtDecode(data.token);
  } catch (error) {
    return error;
  }
};

function* loginWithEmailPassword({ payload }) {
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, payload.user);
    if (!loginUser.message) {
      const { funcionalidades, idRolActivo, nombre, imgURL, id } = loginUser;
      const item = { funcionalidades, idRolActivo, nombre, imgURL, id };

      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

const logoutAsync = async (history) => {
  history.push(adminRoot);
};

function* logout({ payload }) {
  setCurrentUser();

  yield call(logoutAsync, payload.history);
}

const forgotPasswordRequest = async (payload) => {
  try {
    return await API.post(`${MAIN_URI}/olvido-password`, payload);
  } catch (error) {
    throw error;
  }
};

function* forgotPassword({ payload }) {
  try {
    const res = yield call(forgotPasswordRequest, payload);
    yield put(forgotPasswordSuccess(res.data));
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

const resetPasswordAsync = async (payload) => {
  try {
    return await API.post(`${MAIN_URI}/cambia-clave`, payload);
  } catch (error) {
    throw error;
  }
};

function* resetPassword({ payload }) {
  try {
    const res = yield call(resetPasswordAsync, payload);
    yield put(resetPasswordSuccess(res.data));
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

const checkCodeRequest = async (payload) => {
  try {
    return await API.post(`${MAIN_URI}/verifica-codigo`, payload);
  } catch (error) {
    throw error;
  }
};

function* checkCode({ payload }) {
  try {
    const res = yield call(checkCodeRequest, payload);
    yield put(checkCodeSuccess(res.data));
  } catch (error) {
    yield put(checkCodeError(error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchCheckCode() {
  yield takeEvery(CHECK_CODE, checkCode);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchCheckCode),
  ]);
}
