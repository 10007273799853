/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'es';
export const localeOptions = [{ id: 'es', name: 'Español', direction: 'ltr' }];
export const TIMEZONE = 'America/Mexico_City';
export const pageSizes = [4, 8, 12, 20];
export const defCurrentPage = 1;
export const defPageSize = 8;
export const defPageSizeTP = 20;
export const defDisplayMode = 'list';
export const isDarkSwitchActive = true;
export const maxRangoDiasFactura = 90;

export const adminRoot = '/app';

export const themeColorStorageKey = '__theme_selected_color';
export const defaultColor = 'light.macro';
export const darkDefaultColor = 'dark.macro';
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const colors = ['macro'];
